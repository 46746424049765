import React, { Component } from 'react';
import { withRouter } from "react-router";
import AnimatedNumber from 'react-animated-number';
import alert from './static/alert.svg';
import check from './static/check.svg';
import './App.css';
import qs from 'qs';

class App extends Component {

    state = {
        sinOperativo: 0,
        cantidadReefer: 0,
        ejecutivos: [],
        naves: [],
        show: "naves",
        key: null
    }

    key = "null";
    urlEndpoint = "https://cencosud2.rossi.cl/web/api/ejecutivo?key=";

    componentDidMount() {
        this.key = qs.parse(this.props.location.search, { ignoreQueryPrefix: true })._k
        console.log(this.key)
        this.getItems();
    }

    componentWillUnmount() { }

    toggleShow() {
        if ('ejecutivos' === this.state.show) {
            this.setState({ show: 'naves' });
        } else {
            this.setState({ show: 'ejecutivos' });
        }
    }

    getItems() {
        fetch(`${this.urlEndpoint}${this.key}`)
            .then(result => result.json())
            .then(result => {
                this.toggleShow()
                this.setState(result)
                setTimeout(
                    function () {
                        this.getItems()
                    }
                        .bind(this),
                    15000
                );
            }).catch(() => {
                setTimeout(
                    function () {
                        this.getItems()
                    }
                        .bind(this),
                    15000
                );
            });
    }

    render() {

        let ejecutivostmp = this.state.ejecutivos.map((ejecutivo, i) => {
            return <div key={i} className={"user-row font-md " + (i % 2 === 0 ? 'row-bg' : '')}>
                <div className="user-name w-label">{ejecutivo.nombre}</div>
                <div className={"user-data text-right w-10 " + (ejecutivo.data[0] === 0 ? 'color-grey' : '')}><AnimatedNumber stepPrecision={0} component="span" value={ejecutivo.data[0]} duration={800} /></div>
                <div className={"user-data text-right w-10 " + (ejecutivo.data[1] === 0 ? 'color-grey' : '')}><AnimatedNumber stepPrecision={0} component="span" value={ejecutivo.data[1]} duration={800} /></div>
                <div className={"user-data text-right w-10 " + (ejecutivo.data[2] === 0 ? 'color-grey' : '')}><AnimatedNumber stepPrecision={0} component="span" value={ejecutivo.data[2]} duration={800} /></div>
            </div>
        });

        let navestmp = this.state.naves.map((nave, i) => {
            return <div key={i} className={"user-row font-md " + (i % 2 === 0 ? 'row-bg' : '')}>
                <div className="user-name w-label">{nave.nombre}</div>
                <div className="user-name text-right w-label">{nave.eta}</div>
                <div className="user-name text-right w-label">{nave.aduana}</div>
            </div>
        });

        let icon = alert;
        if (this.state.sinOperativo === 0) {
            icon = check;
        }

        let iconReefer = alert;
        if (this.state.cantidadReefer === 0) {
            iconReefer = check;
        }

        return (
            <div className="App">
                <div className="toolbar text-center">
                    <img className="logo-agencia" src="https://www.rossi.cl/piero/images/diseno/logo_rossi_piero.png" alt="Agencia"></img>
                </div>
                <header className="App-header">
                    <div>
                        <p className="App-logo">
                            <AnimatedNumber component="span" stepPrecision={0} value={this.state.sinOperativo} duration={800} />
                        </p>
                        <div className="d-flex">
                            <img src={icon} className="alert-icon" alt="Atención"></img>
                            <div>
                                <p className="font-md margin-0">Operaciones</p>
                                <p className="font-md margin-0">Sin Ejecutivo</p>
                            </div>
                        </div>
                    </div>
                    <div>
                        <p className="App-logo">
                            <AnimatedNumber component="span" stepPrecision={0} value={this.state.cantidadReefer} duration={800} />
                        </p>
                        <div className="d-flex">
                            <img src={iconReefer} className="alert-icon" alt="Atención"></img>
                            <div>
                                <p className="font-md margin-0">Operaciones Reefer</p>
                                <p className="font-md margin-0">Sin Programar</p>
                            </div>
                        </div>
                    </div>
                </header>
                <section className="App-right">
                    {this.state.show === 'ejecutivos' ?
                        <div className="w-100 text-left">
                            <div className="user-row font-md">
                                <div className="user-name w-label"><p className="title">Ejecutivos</p></div>
                                <div className="user-data text-right w-10 color1">
                                    <p className="margin-0 small">Operaciones</p>
                                    <p className="margin-0 margin-top--1">Sin Tramitar</p>
                                </div>
                                <div className="user-data text-right w-10 color2">
                                    <p className="margin-0 small">Operaciones con</p>
                                    <p className="margin-0 margin-top--1">Carga En Piso</p>
                                </div>
                                <div className="user-data text-right w-10 color3">
                                    <p className="margin-0 small">Operaciones con</p>
                                    <p className="margin-0 margin-top--1">BL SIN CANJEAR</p>
                                </div>
                            </div>
                            {ejecutivostmp}
                        </div>
                        : null}
                    {this.state.show === 'naves' ?
                        <div className="w-100 text-left">
                            <div className="user-row font-md">
                                <div className="user-name w-label"><p className="title">Próximas Naves</p></div>
                                <div className="user-data text-right w-10 color1">
                                    <p className="margin-0 small">-</p>
                                    <p className="margin-0 margin-top--1">ETA</p>
                                </div>
                                <div className="user-data text-right w-10 color2">
                                    <p className="margin-0 small">-</p>
                                    <p className="margin-0 margin-top--1">Aduana</p>
                                </div>
                            </div>
                            {navestmp}
                        </div>
                        : null}
                </section>
            </div>
        );
    }
}

export default withRouter(App);
